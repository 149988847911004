import { writable } from 'svelte/store';
import { sortDropDown } from './constants';

// Main Query
export const currentQuery = writable('');

// Filters
const createDDBox = (defaultValue = '') => {
  const { subscribe, set } = writable(defaultValue);

  return {
    subscribe,
    set,
    reset: () => set(defaultValue),
  };
};

export const currentCountry = createDDBox();
export const currentState = createDDBox();
export const currentCert = createDDBox();
export const sortBy = createDDBox(sortDropDown[0].value);
