<script>
    import { getContext, createEventDispatcher } from 'svelte';
    import {
        currentQuery,
        currentCountry,
        currentState,
        currentCert,
        sortBy,
    } from '../stores.js';
    import { sortDropDown } from '../constants';
    import NearSearchDropDown from './near-search-dd.svelte';
    import NearSearchLoading from './near-search-loading.svelte';

    // Dispatcher
    const dispatch = createEventDispatcher();

    // Context Variables
    const countries = getContext('countries');
    const states = getContext('states');
    const certs = getContext('certs');

    // Props
    export let isLoading;
    let timeoutCatcher;

    // Events
    const handleChange = (isTextInput = false) => () => {
      clearTimeout(timeoutCatcher);
      timeoutCatcher = setTimeout(() => {
        dispatch('change', {
            search: $currentQuery,
            country: $currentCountry,
            state: $currentState,
            cert: $currentCert,
            sort: $sortBy,
            page: 1,
          });
      }, isTextInput ? 400 : 0);

    };

    const resetFilter = () => {
        currentQuery.set('');

        [
            currentCountry,
            currentState,
            currentCert,
            sortBy,
        ].forEach(storeItem => storeItem.reset());

        handleChange()();
    };
</script>

<div class="near-search__filter">
    <div class="near-search__filter-main">
        <div class="near-search__filter-query">
            <div class="near-search__filter-query-wrap">
                <input
                    type="search"
                    class="near-search__filter-query-input"
                    placeholder="Search by Name or Company"
                    bind:value="{ $currentQuery }"
                    on:input="{ handleChange(true) }"
                >
                <div class="near-search__filter-query-icon">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="near-search__filter-other">
            <div class="near-search__filter-other-item near-search__filter-other-item_country">
                <NearSearchDropDown
                    list="{ countries }"
                    title="Country"
                    iconName="map-marker"
                    bind:value="{ $currentCountry }"
                    on:change="{
                        () => {
                            $currentState = '';
                            handleChange()();
                        }
                    }"
                />
            </div>
            <div class="near-search__filter-other-item near-search__filter-other-item_state">
                <NearSearchDropDown
                    list="{ states[$currentCountry] ?? states['US'] }"
                    title="States"
                    iconName="map-marker"
                    isDisabled="{ $currentCountry !== '' && states[$currentCountry] === undefined }"
                    bind:value="{ $currentState }"
                    on:change="{ handleChange() }"
                />
            </div>
            <div class="near-search__filter-other-item near-search__filter-other-item_cert">
                <NearSearchDropDown
                    list="{ certs }"
                    title="Certificate"
                    iconName="id-badge"
                    bind:value="{ $currentCert }"
                    on:change="{ handleChange() }"
                />
            </div>
            <div class="near-search__filter-other-item near-search__filter-other-item_sort">
                <NearSearchDropDown
                    list="{ sortDropDown }"
                    prefix="Sort by"
                    iconName="sort-alpha-asc"
                    bind:value="{ $sortBy }"
                    on:change="{ handleChange() }"
                />
            </div>
        </div>
    </div>
    <div class="near-search__filter-reset">
        <button
            type="button"
            class="near-search__filter-reset-btn"
            on:click="{ resetFilter }"
        >Clear All Filters</button>
    </div>

    {#if isLoading}
        <NearSearchLoading />
    {/if}
</div>