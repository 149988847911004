<script>
    import UserCard from '../../user-card/user-card.svelte';

    export let userCards;
    export let isFirstLoad;
</script>

<div class="near-search__result">
    {#if userCards && userCards.length}
        <ul class="near-search__result-list">
            {#each userCards as card}
                <li
                    class="near-search__result-item"
                    class:near-search__result-item_avatar={ card.photo }
                >
                    <UserCard {...card} />
                </li>
            {/each}
        </ul>
    {:else if !isFirstLoad}
        <div class="near-search__empty">
            <div class="near-search__empty-icon">
                <div class="near-search__empty-dot">
                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                </div>
                <div class="near-search__empty-zoom">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <div class="near-search__empty-text">No results found</div>
        </div>
    {/if}
</div>