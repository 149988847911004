<script >
    export let fullName;
    export let company;
    export let photo;
    export let location;
    export let cert;
</script>

<div class="user-card">
    {#if fullName || company}
        <div class="user-card__head">
            {#if fullName}
                <h3 class="user-card__fullname">{ fullName }</h3>
            {/if}

            {#if company}
                <div class="user-card__company">{ company }</div>
            {/if}
        </div>
    {/if}

    <div class="user-card__content">
        {#if location}
            <div class="user-card__location">
                <div class="user-card__location-icon" aria-hidden="true">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div class="user-card__location-text">{ location }</div>
            </div>
        {/if}

        <div class="user-card__cert">
            <div class="user-card__cert-icon">
                <i class="fa fa-certificate" aria-hidden="true"></i>
            </div>
            <div class="user-card__cert-content">
                <div class="user-card__cert-title">Active Certifications:</div>
                <div class="user-card__cert-result">
                    {#if cert}
                        <div class="user-card__cert-list">
                            {#each cert as {name, url}}
                                <div class="user-card__cert-item">
                                    <a
                                        href="{ url }"
                                        class="user-card__cert-link"
                                        target="_blank"
                                    >{ name }</a>
                                </div>
                            {/each}
                        </div>
                    {:else}
                        *No active certifications
                    {/if}
                </div>
            </div>
        </div>
    </div>


    {#if photo}
        <div class="user-card__media">
            <div class="user-card__media-inner">
                <img
                    src="{ photo }"
                    alt="{ fullName }"
                />
            </div>
        </div>
    {/if}
</div>