<script>
    import axios from 'axios';
    import { setContext, onMount } from 'svelte';
    import {
        currentQuery,
        currentCountry,
        currentState,
        currentCert,
        sortBy,
    } from './stores';

    // Components
    import NearSearchFilter from './components/near-search-filter.svelte';
    import NearSearchResult from './components/near-search-result.svelte';

    export let countries;
    export let states;
    export let certs;
    let userCards = [];
    let latestParams;
    let latestPage = 1;
    let isShowNext = false;
    let isLoading;
    let isFirstLoad = true;

    setContext('countries', countries);
    setContext('states', states);
    setContext('certs', certs);

    const buildSearchStr = (params) => {
        if (params && typeof params === 'object') {
            const searchStr = Object
                .keys(params)
                .filter(item => item !== 'page' && params[item])
                .map((item) => {
                    if (params[item]) {
                        return `${item}=${params[item]}`;
                    }
                })
                .join('&');

                window.history.replaceState(params, document.title, `?${searchStr}`);
            }
    };

    function filterCards(params = { page: 1 }) {
        isLoading = true;
        latestParams = params;

        buildSearchStr(params);

        axios.get('/api/search-credentials/', {
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                const {
                  userCertificates,
                  users,
                  isNext,
                } = response.data;
                const newUserList = users.map((user) => ({
                    ...user,
                    cert: userCertificates[user.id],
                }));

                if (latestPage === 1) {
                    userCards = newUserList;
                } else {
                    userCards = [
                        ...userCards,
                        ...newUserList,
                    ];
                }

                // Check if next cards available
                isShowNext = isNext;
            }
        })
        .catch(function (error) {
            userCards = [];
            console.error(error);
        })
        .then(function () {
            isLoading = false;

            if (isFirstLoad) {
                isFirstLoad = false;
            }
        });
    }

    const checkSearchParams = () => {
      if (window.location.search) {
        const searchString = window.location.search.slice(1);
        const normalizedParams = searchString.split('#')[0];
        const arrayOfParams = normalizedParams.split('&');
        let resultObj = {};

        if (arrayOfParams && arrayOfParams.length) {
          arrayOfParams.forEach((item) => {
            let [name, value] = item.split('=');

            // Set State
            switch (name) {
              case 'search':
                value = decodeURIComponent(value.replace(/\+/g, ' '));
                currentQuery.set(value);
                break;
              case 'country':
                value = decodeURIComponent(value);
                currentCountry.set(value);
                break;
              case 'state':
                value = decodeURIComponent(value);
                currentState.set(value);
                break;
              case 'cert':
                currentCert.set(value);
                break;
              case 'sort':
                sortBy.set(value);
                break;
            }

            // Add to query
            resultObj = {
              ...resultObj,
              [name]: value,
            };
          });

          resultObj = {
            ...resultObj,
            page: 1,
          };
        }

        return resultObj;
      }
    };

    onMount(() => {
        const initialParams = checkSearchParams();

        filterCards(initialParams);
	});

    const handleFilterChange = (e) => {
        latestPage = 1;
        filterCards(e.detail);
    };

    const handleLoadMore = () => {
        latestPage = latestPage + 1;

        filterCards({
            ...latestParams,
            page: latestPage,
        });
    };
</script>

<NearSearchFilter
    { isLoading }
    on:change="{ handleFilterChange }"
/>
<NearSearchResult
    { userCards }
    { isFirstLoad }
/>
{#if isShowNext}
    <div class="near-search__more">
        <button
            type="button"
            class="near-search__more-btn"
            on:click="{ handleLoadMore }"
            disabled="{ isLoading }"
        >Load More</button>
    </div>
{/if}
