export default (() => {
  let notificationsBlock;
  let notificationsPopup;
  let isVisiblePopup;
  let currentPopup;

  const closePopup = () => {
    const closeBtn = currentPopup.querySelector('.notification-popup__close');
    const closeBg = currentPopup.querySelector('.notification-popup__bg');

    currentPopup.classList.remove('notification-popup_visible');
    currentPopup.ariaHidden = true;
    isVisiblePopup = false;

    closeBtn.removeEventListener('click', closePopup);
    closeBg.removeEventListener('click', closePopup);

    currentPopup = null;
  };

  const showNotifPopup = (e) => {
    const { target } = e;

    if (target.classList.contains('profile-notif__more-btn') && !isVisiblePopup) {
      const popupID = target.dataset.notification;

      currentPopup = [...notificationsPopup].find((popup) => popup.dataset.id === popupID);

      if (currentPopup) {
        const closeBtn = currentPopup.querySelector('.notification-popup__close');
        const closeBg = currentPopup.querySelector('.notification-popup__bg');

        currentPopup.classList.add('notification-popup_visible');
        currentPopup.ariaHidden = false;
        isVisiblePopup = true;

        closeBtn.addEventListener('click', closePopup);
        closeBg.addEventListener('click', closePopup);
      }
    }
  };

  const events = () => {
    notificationsBlock.addEventListener('click', showNotifPopup);
  };

  const init = () => {
    notificationsBlock = document.querySelector('.profile-notif');
    notificationsPopup = document.querySelectorAll('.notification-popup');

    if (notificationsBlock && notificationsPopup.length) {
      events();
    }
  };

  return {
    init,
  };
})();
