<script>
    import { createEventDispatcher } from 'svelte';

    export let list = null;
    export let value = null;
    export let title = null;
    export let prefix = null;
    export let iconName = null;
    export let isDisabled = false;
    // Dispatcher
    const dispatch = createEventDispatcher();

    const handleDropDownChange = () => {
      dispatch('change');
    };
</script>

{#if list && list.length}
    <div class="near-search__filter-dd">
        {#if iconName}
            <div class="near-search__filter-dd-icon">
                <i class="fa fa-{ iconName }" aria-hidden="true"></i>
            </div>
        {/if}

        <select
            { title }
            bind:value="{ value }"
            class="near-search__filter-dd-select"
            class:near-search__filter-dd-select_active={ value }
            on:change="{ handleDropDownChange }"
            disabled="{isDisabled}"
        >
            {#if title}
                <option value>{ title }</option>
            {/if}

            {#each list as {label, value}}
                <option { value }>
                    { prefix ? `${ prefix } ` : '' }{ label }
                </option>
            {/each}
        </select>
    </div>
{/if}
