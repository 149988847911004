import NearSearch from './near-search.svelte';

export default (() => {
  const init = () => {
    const target = document.querySelector('.near-search__main');

    if (target) {
      const countries = JSON.parse(target.dataset.countries);
      const states = JSON.parse(target.dataset.states);
      const certs = JSON.parse(target.dataset.certs);

      // Clear all inner content and rerender
      target.innerHTML = null;

      // Init Svelte App
      new NearSearch({
        target,
        props: {
          countries,
          states,
          certs,
        },
      });

      delete target.dataset.countries;
      delete target.dataset.states;
      delete target.dataset.certs;
    }
  };

  return {
    init,
  };
})();
