export default (() => {
  const browserParams = {};

  // Remove no-js class
  document.documentElement.classList.remove('no-js');

  // Detect Touch
  const touch = 'ontouchstart' in document.documentElement
              || navigator.maxTouchPoints > 0
              || navigator.msMaxTouchPoints > 0;

  if (!touch) {
    document
      .documentElement
      .classList
      .add('no-touch');
  }

  browserParams.isTouch = touch;

  // Detect CSS Properties
  browserParams.notSupport = {};

  // Detect CSS Properties - Object Fit
  if (!('objectFit' in document.documentElement.style)) {
    browserParams.notSupport.objectFit = true;

    document
      .documentElement
      .classList
      .add('no-object-fit');
  }

  // Set Params in Global
  window.browserParams = browserParams;
})();
