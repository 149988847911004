export const sortDropDown = [{
  label: 'Last Name',
  value: 'lastName',
}, {
  label: 'First Name',
  value: 'firstName',
}, {
  label: 'Company',
  value: 'field_company',
}, {
  label: 'Country',
  value: 'countryCode',
}, {
  label: 'State',
  value: 'administrativeArea',
}];
